.menu-principal {


  position: fixed;


  width: 100%;
  height: auto;
  background: none;


  display: flex;
  justify-content: space-between;




  .logo{
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: 20px;
    width: 150px;
    height: 150px;




  }

  @keyframes clockwise {
    0% {
      top: -5px;
      left: 0;
    }
    12% {
      top: -2px;
      left: 2px;
    }
    25% {
      top: 0;
      left: 5px;
    }
    37% {
      top: 2px;
      left: 2px;
    }
    50% {
      top: 5px;
      left: 0;
    }
    62% {
      top: 2px;
      left: -2px;
    }
    75% {
      top: 0;
      left: -5px;
    }
    87% {
      top: -2px;
      left: -2px;
    }
    100% {
      top: -5px;
      left: 0;
    }
  }

  @keyframes counterclockwise {
    0% {
      top: -5px;
      right: 0;
    }
    12% {
      top: -2px;
      right: 2px;
    }
    25% {
      top: 0;
      right: 5px;
    }
    37% {
      top: 2px;
      right: 2px;
    }
    50% {
      top: 5px;
      right: 0;
    }
    62% {
      top: 2px;
      right: -2px;
    }
    75% {
      top: 0;
      right: -5px;
    }
    87% {
      top: -2px;
      right: -2px;
    }
    100% {
      top: -5px;
      right: 0;
    }
  }


}







.menu-principal img {

  width: 35%;
  margin: 25px;

  &:hover{

  }

}

.menu-principal ul{

  display: flex;
  flex-direction: row;
  position: static;

}

.menu-principal li{

  text-decoration: none;
  list-style: none;
  margin:  20px 40px 0 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  text-transform: uppercase;

  position: relative;


}
.menu-principal li:after{
  background: none repeat scroll 0 0 transparent;
  bottom: 10px;
  content: "";
  display: block;
  height: 10px;
  left: 0;
  position: absolute;
  background: rgba(51, 236, 239, 0.4);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;

}

.menu-principal li:hover:after{

  width: 45%;
  right: 45%;
}


.header-page {

  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

}

.header-title{

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50%;
  //position: absolute;
  //top: 46%;

  //right: 38.5%;
}

.header-title span{

  font-family: 'Source Sans Pro', sans-serif;
  margin: 0;
  font-weight: bold;
  font-size: 48px;

  display: block;
  text-shadow: 0 0 80px rgba(255,255,255,.5);

  /* Clip Background Image */

  background: url("../img/bg-anim.png") repeat-y;
  -webkit-background-clip: text;
  background-clip: text;

  /* Animate Background Image */

  -webkit-text-fill-color: transparent;
  -webkit-animation: aitf 80s linear infinite;

  /* Activate hardware acceleration for smoother animations */

  -webkit-transform: translate3d(0,0,0);
  -webkit-backface-visibility: hidden;

}

@-webkit-keyframes aitf {
  0% { background-position: 0 50%; }
  100% { background-position: 100% 50%; }
}


.header-title p{

  margin: 0;
  font-size: 24px;

}








//================================== scroll button


#section10 a {
  padding-top: 60px;
}
#section10 a span {
  position: absolute;
  bottom: 2%;
  left: 50%;
  width: 30px;
  height: 50px;
  margin-left: -15px;
  border: 2px solid $base-yellow-color;
  border-radius: 50px;
  box-sizing: border-box;
}
#section10 a span::before {
  position: absolute;
  bottom: 80%;
  left: 50%;
  content: '';
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: $base-blue-color;
  border-radius: 100%;
  -webkit-animation: sdb10 2s infinite;
  animation: sdb10 2s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb10 {
  0% {
    -webkit-transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sdb10 {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}



//===========================================shapes========================/

.Header__shape {
  animation: 4s;
  animation-timing-function: cubic-bezier(.18,1.17,.03,1.46);
  animation-fill-mode: backwards;
  transform-origin: center;
  // this here is the good stuff
  transform-box: fill-box;
}



.Header__svg {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  will-change: transform;
}


.bigSquare {
  animation-name: bigSquare;
}
@keyframes bigSquare {
  from { transform: translateY(10%) rotate(-80deg) scale(0); }
  to { transform: translateY(0) rotate(0deg) scale(1); }
}
.littleSquare {
  animation-name: littleSquare;
}
@keyframes littleSquare {
  from { transform: translate(226%, 183%) rotate(140deg) scale(0) ; }
  to { transform: translate(0%, 0%) rotate(0deg) scale(1); }
}
.triangle {
  animation-name: triangle;
}
@keyframes triangle {
  from { transform: rotate(-140deg) scale(0) ; }
  to { transform: rotate(0deg) scale(1); }
}
.hoop {
  animation-name: hoop;
}
@keyframes hoop {
  from { transform: translate(226%, 183%) rotate(140deg) scale(0) ; }
  to { transform: translate(0%, 0%) rotate(0deg) scale(1); }
}
.bigCircle {
  animation-name: bigCircle;
}
@keyframes bigCircle {
  from { transform: scale(0) translate(60%, 3%); }
  to { transform: scale(1) translate(0%, 0%); }
}
.littleCircle {
  animation-name: littleCircle;
}
@keyframes littleCircle {
  from { transform: scale(0) }
  to { transform: scale(1) }
}

// some lovely sass fun to stagger the animation

@for $i from 1 to 12 {
  .Header__shape:nth-child(#{$i}) {
    animation-delay: $i * 0.16s;
  }
}



// menu responsive burger

.trigger{
  display: none;

}

.modal{
  display: none;
}

.trigger {
  position: fixed;
  // z-index: 1;
  left: 90%;
  top: 7%;
  border: 2px solid $base-blue-color;
  border-radius: 10px;
  transform: translateX(-50%) translateY(-50%);
  width: 45px;
  height: 45px;
}

.bars {
  width: 40px;
  cursor: pointer;
}
.bars .line {
  fill: none;
  stroke: $base-yellow-color;
  stroke-width: 4;
  stroke-linecap: square;
  transition: stroke-dasharray 400ms,  stroke-dashoffset 400ms;
}
.bars .line.top {
  stroke-dasharray: 40 172;
}
.bars .line.middle {
  stroke-dasharray: 40 111;
}
.bars .line.bottom {
  stroke-dasharray: 40 172;
}
.bars.active .top {
  stroke-dashoffset: -132px;
}
.bars.active .middle {
  stroke-dashoffset: -71px;
}
.bars.active .bottom {
  stroke-dashoffset: -132px;
}


//modal====================================================================================




.non-modal{
  position: fixed;
  right: 2%;
  top: 2%;
}


@media screen and (max-width: 768px){

  .trigger{display: block}

  .non-modal{display: none}


}









//-----------



.overlay {
  height: 100%;
  width: 0;
  position: fixed;

  top: 0;
  right: 0;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;

  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;

  ul{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }


}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

/*================Media Breakpoints ====================*/

@media screen and (max-width: 768px) {
  .overlay a {font-size: 20px}
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

@media screen and (max-width: 768px) {


  .menu-principal{

    background-color: rgba(248,245,242, 0.9);

  }

  .trigger {
    position: fixed;
    z-index: 4;
    left: 85%;
    top: 7%;
    border: 2px solid $base-blue-color;
    border-radius: 10px;
    transform: translateX(-50%) translateY(-50%);
  }




  .header-page{


    .header-title{

      span{
        font-size: rem(36);
      }
      p{
        font-size: rem(24);
      }
    }

  }

}







