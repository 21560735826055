.section-competences{

  display: flex;


  margin-top: 20%;
  padding-top: 0;

  height: 100vh;




  .section__shape {
    animation: 4s;
    animation-timing-function: cubic-bezier(.18,1.17,.03,1.46);
    animation-fill-mode: backwards;
    transform-origin: center;
    // this here is the good stuff
    transform-box: fill-box;
  }



  .section__svg {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 315%;
    left: 5%;
    transform: translateY(-50%);
    z-index: -1;
    will-change: transform;
  }





  .titres-sections3{
    writing-mode: vertical-rl;
    text-orientation: sideways;

    margin-left: 20%;
    margin-top: 2%;

    //position: relative;


    span{
      background: rgba(51, 236, 239, 0.4);
      // position: absolute;
      // bottom: 102.5%;
      width: 50%;
      height: 40%;
    }
  }



  .contenu-competences{
    display: flex;
    flex-direction: column;

    .comp1{
      display: flex;

      padding-top: 35px;


      article{

        width: 325px;
        padding: 20px;

        p{
          padding: 0;
          margin: 0;
        }

      }
    }
    .comp2{
      display: flex;

      padding-top: 35px;


      article{

        width: 325px;
        padding: 20px;

        p{
          padding: 0;
          margin: 0;
        }

      }
    }

    .comp3{
      display: flex;

      padding-top: 35px;


      article{

        width: 325px;
        padding: 20px;

        p{
          padding: 0;
          margin: 0;
        }

      }
    }

  }


}



@media screen and (max-width: 768px){

  .section-competences{

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin-top: 20%;
    padding-top: 0;

    height: auto;

    .section__svg{
      display: none;
    }


    .titres-sections3{
      text-orientation: initial;
      writing-mode: horizontal-tb;

      font-size: rem(30);
      margin: 0;

    }



    .contenu-competences{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;


      padding-top: 10px;


      .comp1{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        article{

          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 275px;
          padding: 0;
          text-align: center;

          p{
            padding: 0;
            margin: 0;

          }

        }
      }


      .comp2{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        article{

          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 275px;
          padding: 0;
          text-align: center;

          p{
            padding: 0;
            margin: 0;

          }

        }
      }
      .comp3{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        article{

          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 275px;
          padding: 0;
          text-align: center;

          p{
            padding: 0;
            margin: 0;
          }

        }
      }



    }




  }


}