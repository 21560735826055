

.section-objectif{

  display: flex;
  justify-content: center;
  align-items: center;
//margin-bottom: 20%;

  height: 100vh;



  article{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10%;


    div{

      width: 50%;

    p{
      font-weight: 300;
      font-size: rem(24);
      margin-top: 0;
      margin-bottom: 5px;


      span{
        font-size: rem(64);
      }
    }

  }
  .titres-sections2Desktop{
    writing-mode: vertical-rl;
    text-orientation: sideways;


    //position: relative;

    span{
      background: rgba(255, 221, 70, 0.58);
     // position: absolute;
     // bottom: 105%;
      width: 50%;
      height: 65%;
    }

  }

  }
}


.titres-sections2Mobile{
  display: none;
}






@media screen and (max-width: 768px) {

.section-objectif{



  position: relative;
  z-index: -10;
  height: auto;

  margin-bottom: 10%;

  article{

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;





  div{
    width: 340px;
    p{
      font-size: rem(18);
    }


  }
    .titres-sections2Desktop{

      display: none;
    }
  .titres-sections2Mobile{

    display: block;
    text-orientation: initial;
    writing-mode: horizontal-tb;

    font-size: rem(36);

    //position: relative;

    span{
      background: rgba(255, 221, 70, 0.58);
      // position: absolute;
      // bottom: 105%;
      width: 50%;
      height: 65%;
    }


  }
}
}

}












