

.section-contact{

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;


  .titres-sections4Mobile{
    display: none;
  }

  div{

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 15%;

  }




  .titres-sections4{
    writing-mode: vertical-rl;
    text-orientation: sideways;




    //position: relative;

    span{
      background: rgba(255, 221, 70, 0.58);
      // position: absolute;
      // bottom: 105%;
      width: 50%;
      height: 65%;
    }

  }

}







@media screen and (max-width: 768px){


  .section-contact{

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;

    .titres-sections4Mobile{

      display: block;
      text-orientation: initial;
      writing-mode: horizontal-tb;

      font-size: rem(36);

      //position: relative;

      span{
        background: rgba(255, 221, 70, 0.58);
        // position: absolute;
        // bottom: 105%;
        width: 50%;
        height: 65%;
      }


    }

    div{

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

    }

    .titres-sections4{
      display: none;
    }






  }



}