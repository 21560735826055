// STYLE DE BASE DE TOUS LES LIENS ET BOUTON DU SITE

a{
  text-decoration: none;

}

a:link, a:visited{

  color: #484848;
}
