.titres-sections{
  writing-mode: vertical-rl;
  text-orientation: sideways;

  margin-left: 20%;
  margin-bottom: 2%;

  // position: relative;

  span{
    background: rgba(51, 236, 239, 0.4);
    // position: absolute;
    // bottom: 105%;
    width: 50%;
    height: 75%;
  }
}

.section-projets{

  display: flex;

  //position: relative;

  div{

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 1100px;
    margin-top: 10%;

    article{

      padding: 10px;



      a{

        cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGYAAABuCAYAAADVjGYPAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQ5SURBVHgB7d1PbxtFGMfx3+w64kgsChInHCoqcSIguG8PTXv0kVuTd5C+gqavoPAKSN9Bzhip2ztU5sQBQsIJCYQcULkk7AzzeEWC66wSux3PM7u/j+SqkS0l8de7OzvZPwARERERERERERERERERERERUdsZRFZMJut/VRj6n+Q9JCCzePbt2/0SgUUN89lvk22b4zEc1pGWgyrHzrjfP0EgPUTio2zaDF/5KCka5tX0w3QbgWSIxEd5jLQVn/4+KRBItDBegcT5D9dHCCRmmGDr5xUKtm2MGWYMahQtjHF4BGoULYzsC/gB2Y5/HIPmRBsui+c3+vubv07KLK8K5PnKdzD9p3LdOexCoYXDuKN7A5z5EVVuZUQygDOvtgH8+/Pm56x9YG6NgmyLZMbhhcVTKHWtMO6o8G/+G/f9zuDQv1k+yv+eNAH3ENfwJgL4L4pfWjah1JVh3OHdXTj30D9Smza5VApRRGOY6SrLOT9l4opEp03mpBJFXBqmjmKf+iADtERKUcTccJlRdJgJwyg1/9qxf3yBiGaXGCsbeUaxL/x0fhZ3Lu88jPvpzrb/dxst8SpRxhv9qFHExRKTmYdoidSjiGkY9+O9oi2rsDZEEfUSk7n7aIG2RBF1GOOGSFybooieO7qzCbvsX+LcPrLswA/nwvxiJ2ffX+dlbYsieqjMYLmDmNyuuTn6EpG1MYrIfJTFDyhwrmSUsJb7C6YxJSJrcxQR82CMpbU9ikguTBeiiKTCdCWKSCZMl6KIJMJ0LYpQH6aLUYTqMF2NItSG6XIUoTJM16MIdWEYpaYqDKNcUBOGUWapCMMo81SEYZR5KsJY4OC6r+1CFKEizPO3+o+cwd5Vr+tKFKFm439VnC5FEaqGy01xuhZFqNvBfDlOF6OIqCfHNpE4n/wxkVHBMFoUV/2CmXMa5xkX7oxrtZOYEifmkmLzXEaKjd9bToOvzvAMgaie9o+5+pJLXvk3/0HT8wbYG7/bP0YgSR4lsypyHYLK4mP/31K+dvUSVNocxXc3+k8QkMptTAjucEtOM9nDYvbMO325tEqw65I14RKjFMMoxTBKMYxSDKMUwyjFMEoxjFIMoxTDKMUwSjGMUgyjFMMoxTBKMYxSDKMUwyjFMEoxjFIMo1SGzCx+7JZcVp6CynBm/8SijCnc0VYrrqOpVQ9rGMuZQwuz2HeHdwtYE/TAt9fHDZDQ3SN6ZmM0dj9vnSx391a3jaw9F9HWpN74O3PtU+1oNeowtkpkddQd0zDmg29KGFeC1LjYj6kc70upyHmY6VKzwGndFNbsnn92ugPeMFSFmTBmozzB6amcC3IMimpursx8WB4zTnyXTmKex+FILZrG2WWJY94f3fajNW53Irhy2t/cGu2bm19vTAM5P69GK7HwDUrcD8UA+VqB3NQ3wTbtuNXvpf7BE/lgIoJ/AZxws5nCtD3qAAAAAElFTkSuQmCC'), auto;

        img{

          width: 500px;

        }


      }

    }
  }
}





@media screen and (max-width: 768px) {

  .section-projets{

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;




    div{

      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 360px;

      article{



        a{




          img{

            width: 200px;

          }


        }

      }
    }


    .titres-sections{

      text-orientation: initial;
      writing-mode: horizontal-tb;

      font-size: rem(36);


    }

  }
}

