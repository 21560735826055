html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #F8F8F2;
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  color: #343a40;
  line-height: 1.5;
}

body ::selection {
  background: #33ECEF;
  color: black;
}

h1 {
  font-family: 'Source Sans Pro', sans-serif;
}

h2 {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 6rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.245em;
}

a {
  text-decoration: none;
}

a:link, a:visited {
  color: #484848;
}

.wrapper {
  max-width: 1180px;
  margin: 0 auto;
}

.menu-principal {
  position: fixed;
  width: 100%;
  height: auto;
  background: none;
  display: flex;
  justify-content: space-between;
}

.menu-principal .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  width: 150px;
  height: 150px;
}

@keyframes clockwise {
  0% {
    top: -5px;
    left: 0;
  }
  12% {
    top: -2px;
    left: 2px;
  }
  25% {
    top: 0;
    left: 5px;
  }
  37% {
    top: 2px;
    left: 2px;
  }
  50% {
    top: 5px;
    left: 0;
  }
  62% {
    top: 2px;
    left: -2px;
  }
  75% {
    top: 0;
    left: -5px;
  }
  87% {
    top: -2px;
    left: -2px;
  }
  100% {
    top: -5px;
    left: 0;
  }
}

@keyframes counterclockwise {
  0% {
    top: -5px;
    right: 0;
  }
  12% {
    top: -2px;
    right: 2px;
  }
  25% {
    top: 0;
    right: 5px;
  }
  37% {
    top: 2px;
    right: 2px;
  }
  50% {
    top: 5px;
    right: 0;
  }
  62% {
    top: 2px;
    right: -2px;
  }
  75% {
    top: 0;
    right: -5px;
  }
  87% {
    top: -2px;
    right: -2px;
  }
  100% {
    top: -5px;
    right: 0;
  }
}

.menu-principal img {
  width: 35%;
  margin: 25px;
}

.menu-principal ul {
  display: flex;
  flex-direction: row;
  position: static;
}

.menu-principal li {
  text-decoration: none;
  list-style: none;
  margin: 20px 40px 0 0;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
}

.menu-principal li:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 10px;
  content: "";
  display: block;
  height: 10px;
  left: 0;
  position: absolute;
  background: rgba(51, 236, 239, 0.4);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.menu-principal li:hover:after {
  width: 45%;
  right: 45%;
}

.header-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.header-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 50%;
}

.header-title span {
  font-family: 'Source Sans Pro', sans-serif;
  margin: 0;
  font-weight: bold;
  font-size: 48px;
  display: block;
  text-shadow: 0 0 80px rgba(255, 255, 255, 0.5);
  /* Clip Background Image */
  background: url("../img/bg-anim.png") repeat-y;
  -webkit-background-clip: text;
  background-clip: text;
  /* Animate Background Image */
  -webkit-text-fill-color: transparent;
  -webkit-animation: aitf 80s linear infinite;
  /* Activate hardware acceleration for smoother animations */
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
}

@-webkit-keyframes aitf {
  0% {
    background-position: 0 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.header-title p {
  margin: 0;
  font-size: 24px;
}

#section10 a {
  padding-top: 60px;
}

#section10 a span {
  position: absolute;
  bottom: 2%;
  left: 50%;
  width: 30px;
  height: 50px;
  margin-left: -15px;
  border: 2px solid #FFDB5B;
  border-radius: 50px;
  box-sizing: border-box;
}

#section10 a span::before {
  position: absolute;
  bottom: 80%;
  left: 50%;
  content: '';
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: #33ECEF;
  border-radius: 100%;
  -webkit-animation: sdb10 2s infinite;
  animation: sdb10 2s infinite;
  box-sizing: border-box;
}

@-webkit-keyframes sdb10 {
  0% {
    -webkit-transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes sdb10 {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.Header__shape {
  animation: 4s;
  animation-timing-function: cubic-bezier(0.18, 1.17, 0.03, 1.46);
  animation-fill-mode: backwards;
  transform-origin: center;
  transform-box: fill-box;
}

.Header__svg {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  will-change: transform;
}

.bigSquare {
  animation-name: bigSquare;
}

@keyframes bigSquare {
  from {
    transform: translateY(10%) rotate(-80deg) scale(0);
  }
  to {
    transform: translateY(0) rotate(0deg) scale(1);
  }
}

.littleSquare {
  animation-name: littleSquare;
}

@keyframes littleSquare {
  from {
    transform: translate(226%, 183%) rotate(140deg) scale(0);
  }
  to {
    transform: translate(0%, 0%) rotate(0deg) scale(1);
  }
}

.triangle {
  animation-name: triangle;
}

@keyframes triangle {
  from {
    transform: rotate(-140deg) scale(0);
  }
  to {
    transform: rotate(0deg) scale(1);
  }
}

.hoop {
  animation-name: hoop;
}

@keyframes hoop {
  from {
    transform: translate(226%, 183%) rotate(140deg) scale(0);
  }
  to {
    transform: translate(0%, 0%) rotate(0deg) scale(1);
  }
}

.bigCircle {
  animation-name: bigCircle;
}

@keyframes bigCircle {
  from {
    transform: scale(0) translate(60%, 3%);
  }
  to {
    transform: scale(1) translate(0%, 0%);
  }
}

.littleCircle {
  animation-name: littleCircle;
}

@keyframes littleCircle {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.Header__shape:nth-child(1) {
  animation-delay: 0.16s;
}

.Header__shape:nth-child(2) {
  animation-delay: 0.32s;
}

.Header__shape:nth-child(3) {
  animation-delay: 0.48s;
}

.Header__shape:nth-child(4) {
  animation-delay: 0.64s;
}

.Header__shape:nth-child(5) {
  animation-delay: 0.8s;
}

.Header__shape:nth-child(6) {
  animation-delay: 0.96s;
}

.Header__shape:nth-child(7) {
  animation-delay: 1.12s;
}

.Header__shape:nth-child(8) {
  animation-delay: 1.28s;
}

.Header__shape:nth-child(9) {
  animation-delay: 1.44s;
}

.Header__shape:nth-child(10) {
  animation-delay: 1.6s;
}

.Header__shape:nth-child(11) {
  animation-delay: 1.76s;
}

.trigger {
  display: none;
}

.modal {
  display: none;
}

.trigger {
  position: fixed;
  left: 90%;
  top: 7%;
  border: 2px solid #33ECEF;
  border-radius: 10px;
  transform: translateX(-50%) translateY(-50%);
  width: 45px;
  height: 45px;
}

.bars {
  width: 40px;
  cursor: pointer;
}

.bars .line {
  fill: none;
  stroke: #FFDB5B;
  stroke-width: 4;
  stroke-linecap: square;
  transition: stroke-dasharray 400ms,  stroke-dashoffset 400ms;
}

.bars .line.top {
  stroke-dasharray: 40 172;
}

.bars .line.middle {
  stroke-dasharray: 40 111;
}

.bars .line.bottom {
  stroke-dasharray: 40 172;
}

.bars.active .top {
  stroke-dashoffset: -132px;
}

.bars.active .middle {
  stroke-dashoffset: -71px;
}

.bars.active .bottom {
  stroke-dashoffset: -132px;
}

.non-modal {
  position: fixed;
  right: 2%;
  top: 2%;
}

@media screen and (max-width: 768px) {
  .trigger {
    display: block;
  }
  .non-modal {
    display: none;
  }
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  top: 0;
  right: 0;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay-content ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

/*================Media Breakpoints ====================*/
@media screen and (max-width: 768px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

@media screen and (max-width: 768px) {
  .menu-principal {
    background-color: rgba(248, 245, 242, 0.9);
  }
  .trigger {
    position: fixed;
    z-index: 4;
    left: 85%;
    top: 7%;
    border: 2px solid #33ECEF;
    border-radius: 10px;
    transform: translateX(-50%) translateY(-50%);
  }
  .header-page .header-title span {
    font-size: 2.25rem;
  }
  .header-page .header-title p {
    font-size: 1.5rem;
  }
}

.titres-sections {
  writing-mode: vertical-rl;
  text-orientation: sideways;
  margin-left: 20%;
  margin-bottom: 2%;
}

.titres-sections span {
  background: rgba(51, 236, 239, 0.4);
  width: 50%;
  height: 75%;
}

.section-projets {
  display: flex;
}

.section-projets div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 1100px;
  margin-top: 10%;
}

.section-projets div article {
  padding: 10px;
}

.section-projets div article a {
  cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGYAAABuCAYAAADVjGYPAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQ5SURBVHgB7d1PbxtFGMfx3+w64kgsChInHCoqcSIguG8PTXv0kVuTd5C+gqavoPAKSN9Bzhip2ztU5sQBQsIJCYQcULkk7AzzeEWC66wSux3PM7u/j+SqkS0l8de7OzvZPwARERERERERERERERERERERUdsZRFZMJut/VRj6n+Q9JCCzePbt2/0SgUUN89lvk22b4zEc1pGWgyrHzrjfP0EgPUTio2zaDF/5KCka5tX0w3QbgWSIxEd5jLQVn/4+KRBItDBegcT5D9dHCCRmmGDr5xUKtm2MGWYMahQtjHF4BGoULYzsC/gB2Y5/HIPmRBsui+c3+vubv07KLK8K5PnKdzD9p3LdOexCoYXDuKN7A5z5EVVuZUQygDOvtgH8+/Pm56x9YG6NgmyLZMbhhcVTKHWtMO6o8G/+G/f9zuDQv1k+yv+eNAH3ENfwJgL4L4pfWjah1JVh3OHdXTj30D9Smza5VApRRGOY6SrLOT9l4opEp03mpBJFXBqmjmKf+iADtERKUcTccJlRdJgJwyg1/9qxf3yBiGaXGCsbeUaxL/x0fhZ3Lu88jPvpzrb/dxst8SpRxhv9qFHExRKTmYdoidSjiGkY9+O9oi2rsDZEEfUSk7n7aIG2RBF1GOOGSFybooieO7qzCbvsX+LcPrLswA/nwvxiJ2ffX+dlbYsieqjMYLmDmNyuuTn6EpG1MYrIfJTFDyhwrmSUsJb7C6YxJSJrcxQR82CMpbU9ikguTBeiiKTCdCWKSCZMl6KIJMJ0LYpQH6aLUYTqMF2NItSG6XIUoTJM16MIdWEYpaYqDKNcUBOGUWapCMMo81SEYZR5KsJY4OC6r+1CFKEizPO3+o+cwd5Vr+tKFKFm439VnC5FEaqGy01xuhZFqNvBfDlOF6OIqCfHNpE4n/wxkVHBMFoUV/2CmXMa5xkX7oxrtZOYEifmkmLzXEaKjd9bToOvzvAMgaie9o+5+pJLXvk3/0HT8wbYG7/bP0YgSR4lsypyHYLK4mP/31K+dvUSVNocxXc3+k8QkMptTAjucEtOM9nDYvbMO325tEqw65I14RKjFMMoxTBKMYxSDKMUwyjFMEoxjFIMoxTDKMUwSjGMUgyjFMMoxTBKMYxSDKMUwyjFMEoxjFIMo1SGzCx+7JZcVp6CynBm/8SijCnc0VYrrqOpVQ9rGMuZQwuz2HeHdwtYE/TAt9fHDZDQ3SN6ZmM0dj9vnSx391a3jaw9F9HWpN74O3PtU+1oNeowtkpkddQd0zDmg29KGFeC1LjYj6kc70upyHmY6VKzwGndFNbsnn92ugPeMFSFmTBmozzB6amcC3IMimpursx8WB4zTnyXTmKex+FILZrG2WWJY94f3fajNW53Irhy2t/cGu2bm19vTAM5P69GK7HwDUrcD8UA+VqB3NQ3wTbtuNXvpf7BE/lgIoJ/AZxws5nCtD3qAAAAAElFTkSuQmCC"), auto;
}

.section-projets div article a img {
  width: 500px;
}

@media screen and (max-width: 768px) {
  .section-projets {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .section-projets div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 360px;
  }
  .section-projets div article a img {
    width: 200px;
  }
  .section-projets .titres-sections {
    text-orientation: initial;
    writing-mode: horizontal-tb;
    font-size: 2.25rem;
  }
}

.section-objectif {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.section-objectif article {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
}

.section-objectif article div {
  width: 50%;
}

.section-objectif article div p {
  font-weight: 300;
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 5px;
}

.section-objectif article div p span {
  font-size: 4rem;
}

.section-objectif article .titres-sections2Desktop {
  writing-mode: vertical-rl;
  text-orientation: sideways;
}

.section-objectif article .titres-sections2Desktop span {
  background: rgba(255, 221, 70, 0.58);
  width: 50%;
  height: 65%;
}

.titres-sections2Mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .section-objectif {
    position: relative;
    z-index: -10;
    height: auto;
    margin-bottom: 10%;
  }
  .section-objectif article {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .section-objectif article div {
    width: 340px;
  }
  .section-objectif article div p {
    font-size: 1.125rem;
  }
  .section-objectif article .titres-sections2Desktop {
    display: none;
  }
  .section-objectif article .titres-sections2Mobile {
    display: block;
    text-orientation: initial;
    writing-mode: horizontal-tb;
    font-size: 2.25rem;
  }
  .section-objectif article .titres-sections2Mobile span {
    background: rgba(255, 221, 70, 0.58);
    width: 50%;
    height: 65%;
  }
}

.section-competences {
  display: flex;
  margin-top: 20%;
  padding-top: 0;
  height: 100vh;
}

.section-competences .section__shape {
  animation: 4s;
  animation-timing-function: cubic-bezier(0.18, 1.17, 0.03, 1.46);
  animation-fill-mode: backwards;
  transform-origin: center;
  transform-box: fill-box;
}

.section-competences .section__svg {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 315%;
  left: 5%;
  transform: translateY(-50%);
  z-index: -1;
  will-change: transform;
}

.section-competences .titres-sections3 {
  writing-mode: vertical-rl;
  text-orientation: sideways;
  margin-left: 20%;
  margin-top: 2%;
}

.section-competences .titres-sections3 span {
  background: rgba(51, 236, 239, 0.4);
  width: 50%;
  height: 40%;
}

.section-competences .contenu-competences {
  display: flex;
  flex-direction: column;
}

.section-competences .contenu-competences .comp1 {
  display: flex;
  padding-top: 35px;
}

.section-competences .contenu-competences .comp1 article {
  width: 325px;
  padding: 20px;
}

.section-competences .contenu-competences .comp1 article p {
  padding: 0;
  margin: 0;
}

.section-competences .contenu-competences .comp2 {
  display: flex;
  padding-top: 35px;
}

.section-competences .contenu-competences .comp2 article {
  width: 325px;
  padding: 20px;
}

.section-competences .contenu-competences .comp2 article p {
  padding: 0;
  margin: 0;
}

.section-competences .contenu-competences .comp3 {
  display: flex;
  padding-top: 35px;
}

.section-competences .contenu-competences .comp3 article {
  width: 325px;
  padding: 20px;
}

.section-competences .contenu-competences .comp3 article p {
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .section-competences {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20%;
    padding-top: 0;
    height: auto;
  }
  .section-competences .section__svg {
    display: none;
  }
  .section-competences .titres-sections3 {
    text-orientation: initial;
    writing-mode: horizontal-tb;
    font-size: 1.875rem;
    margin: 0;
  }
  .section-competences .contenu-competences {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 10px;
  }
  .section-competences .contenu-competences .comp1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .section-competences .contenu-competences .comp1 article {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 275px;
    padding: 0;
    text-align: center;
  }
  .section-competences .contenu-competences .comp1 article p {
    padding: 0;
    margin: 0;
  }
  .section-competences .contenu-competences .comp2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .section-competences .contenu-competences .comp2 article {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 275px;
    padding: 0;
    text-align: center;
  }
  .section-competences .contenu-competences .comp2 article p {
    padding: 0;
    margin: 0;
  }
  .section-competences .contenu-competences .comp3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .section-competences .contenu-competences .comp3 article {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 275px;
    padding: 0;
    text-align: center;
  }
  .section-competences .contenu-competences .comp3 article p {
    padding: 0;
    margin: 0;
  }
}

.section-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.section-contact .titres-sections4Mobile {
  display: none;
}

.section-contact div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15%;
}

.section-contact .titres-sections4 {
  writing-mode: vertical-rl;
  text-orientation: sideways;
}

.section-contact .titres-sections4 span {
  background: rgba(255, 221, 70, 0.58);
  width: 50%;
  height: 65%;
}

@media screen and (max-width: 768px) {
  .section-contact {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
  }
  .section-contact .titres-sections4Mobile {
    display: block;
    text-orientation: initial;
    writing-mode: horizontal-tb;
    font-size: 2.25rem;
  }
  .section-contact .titres-sections4Mobile span {
    background: rgba(255, 221, 70, 0.58);
    width: 50%;
    height: 65%;
  }
  .section-contact div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .section-contact .titres-sections4 {
    display: none;
  }
}

.u-hidden-visually {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

.u-hidden {
  display: none !important;
}

@media print {
  .hidden-print {
    display: none !important;
  }
}
