// LES STYLES DE BASE DES BALISES <h>


h1{
  font-family: 'Source Sans Pro', sans-serif;

}

h2{
  font-family: 'Source Sans Pro', sans-serif;
  font-size: rem(96);
  font-weight: $fw-bold;

  text-transform: uppercase;
  letter-spacing: 0.245em;

}

