// STYLE DE BASE SUR LA BALISE BODY

html{
  scroll-behavior: smooth;
}

body{

  background-color: #F8F8F2;
  @include font($ff-normal, $fz-normal, $color-text-normal, $lh-normal);


  //.titre-organisateur{
  //  display: flex;
  //  align-items: center;
  //  justify-content: flex-end;
  //  margin-right: 5%;
  //
  //
  //}

  ::selection {
    background: $base-blue-color;
    color: black;
  }




}





