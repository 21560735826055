//FICHIER DE CONFIGURATION POUR LES VARIABLES DE COULEUR

// Couleur de base 
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$color-text-normal: $gray-800;

$color-bg-page: $black;

$color-bg-light: $gray-100;
$color-bg-dark: $gray-800;

$color-title-light: $gray-100;
$color-title-dark: $gray-800;

$color-link: #FF1120;
$color-link-visited: $color-link;
$color-link-hover: lighten($color-link, 25%);
$color-link-active: darken($color-link, 15%);
$color-link-focus: $color-link;


//Couleurs pour formulaire
$base-input-placeholder-color:                  #999 ;
$base-input-color:                              #000 ;
$base-input-background-color:                   #fff ;
$base-input-background-focus-color:             #fff ;
$base-input-border-color:                       #ccc ;
$base-input-border-focus-color:                 #000 ;
$base-red-color:                             #FF1120 ;
$base-yellow-color:                             #FFDB5B ;
$base-blue-color:                             #33ECEF ;



//Couleurs des médias sociaux
$color-facebook: #3b5999;
$color-messenger: #0084ff;
$color-twitter: #55acee;
$color-linkedin: #0077B5;
$color-skype: #00AFF0;
$color-dropbox: #007ee5;
$color-wordpress: #21759b;
$color-vimeo: #1ab7ea;
$color-slideshare: #0077b5;
$color-vk: #4c75a3;
$color-tumblr: #34465d;
$color-yahoo: #410093;
$color-googleplus: #dd4b39;
$color-pinterest: #bd081c;
$color-youtube: #cd201f;
$color-stumbleupon: #eb4924;
$color-reddit: #ff5700;
$color-quora: #b92b27;
$color-yelp: #af0606;
$color-weibo: #df2029;
$color-producthunt: #da552f;
$color-hackernews: #ff6600;
$color-soundcloud: #ff3300;
$color-blogger: #f57d00;
$color-whatsapp: #25D366;
$color-wechat: #09b83e;
$color-line: #00c300;
$color-medium: #02b875;
$color-vine: #00b489;
$color-slack: #3aaf85;
$color-instagram: #e4405f;
$color-dribbble: #ea4c89;
$color-flickr: #ff0084;
$color-foursquare: #f94877;
$color-behance: #131418;
$color-snapchat: #FFFC00;
